<template>
  <div id="AcceptStockIn">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer bom-stock-in-drawer"
      title="接收入库"
      v-model="flag"
      width="500"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form
          :label-width="100"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem class="long" label="单据编号：">
            <Input placeholder="默认系统生成" v-model="formd.BillCode" />
          </FormItem>
          <FormItem label="单据日期：" prop="BillDate" required>
            <DatePicker v-model="formd.BillDate" />
          </FormItem>
          <FormItem label="仓库：" prop="StoreId" required>
            <Select v-model="formd.StoreId">
              <Option
                :key="`storage-${i}`"
                :value="item.Id"
                v-for="(item, i) in storageLst"
              >{{item.Name}}</Option>
            </Select>
          </FormItem>
          <FormItem label="申请人：">
            <Input :value="formd.ApplyUserName" @on-clear="unPickUser" clearable readonly>
              <Button @click="toPickUser" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="入库类型：">
            <!-- <Select v-model="formd.Type">
            <Option :value="1">客户物料入库</Option>
            <Option :value="2" disabled>我方物料入库</Option>
            </Select>-->
            <Select v-model="formd.SubType">
              <Option :value="1">接收入库</Option>
              <Option :value="2" disabled>检毕入库</Option>
              <Option :value="99" disabled>其它入库</Option>
            </Select>
          </FormItem>
          <FormItem class="long" label="备注：">
            <Input type="textarea" v-model="formd.Remark" />
          </FormItem>
          <FormItem label="入库清单：" style="margin:0;"></FormItem>
        </Form>
        <Form
          :key="`stock-in-${i}`"
          :model="lst[i]"
          :ref="`form-${i}`"
          :rules="rules2"
          @submit.native.prevent
          class="stock-in-item"
          inline
          v-for="(a, i) in lst"
        >
          <Button @click="del(i)" class="close" icon="md-close" type="text"></Button>
          <FormItem :label-width="42" label="名称：">
            <div>{{a.Name}}</div>
          </FormItem>
          <!-- <FormItem label="数量：" :label-width="42">
            <div>{{a.RevQty_}}</div>
          </FormItem>-->
          <FormItem :label-width="42" label="入库：">
            <Input :disabled="a.SubType==1" size="small" style="width:50px" v-model="a.Qty" />
          </FormItem>
          <div>
            <FormItem :label-width="56" label="原编号：">
              <div style="width:144px">{{a.Code||"--"}}</div>
            </FormItem>
            <FormItem :label-width="70" label="内部编号：">
              <Input placeholder="默认系统生成样品编号" size="small" style="width:160px" v-model="a.GoodsNo" />
            </FormItem>
          </div>
          <div>
            <FormItem :label-width="70" label="测试项目：">
              <Select size="small" style="width:160px" v-model="a.ItemId">
                <Option
                  :key="`item-${i}-${b.Id}`"
                  :value="b.Id"
                  v-for="b in orderItems"
                >{{ b.Name }}</Option>
              </Select>
            </FormItem>
          </div>
          <FormItem :label-width="42" class="long" label="备注：">
            <Input size="small" style="width:400px" v-model="a.Remark" />
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer">
        <Button :loading="posting" @click="ok" type="primary">入库申请</Button>
      </div>
    </Drawer>
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickUser from "../../components/PickUser.vue";
export default {
  components: { PickUser },
  data() {
    return {
      id: 0,
      orderId: 0,
      orderCode: "",
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: { Type: 1, SubType: 1, Status: 1 },
      rules: {
        BillCode: [{ required: true, message: " " }],
        BillDate: [
          {
            validator: (rule, value, callback) => {
              value ? callback() : callback(new Error(" "));
            },
          },
        ],
        StoreId: [{ required: true, type: "number", message: " " }],
      },
      lst: [],
      rules2: {},
      storageLst: [],
      orderItems: [],
    };
  },
  methods: {
    add(data, orderId, orderCode) {
      this.orderId = orderId;
      this.orderCode = orderCode;
      this.flag = true;
      for (let i in this.lst) {
        if (this.lst[i].BomRevId == data.Id) {
          return;
        }
      }
      this.lst.push({
        ItemId: data.ItemId,
        BomRevId: data.Id,
        Type: 1, // 客户物料
        SubType: data.Type,
        Name: data.Name,
        Code: data.Code,
        GoodsNo: "",
        RevQty_: data.Qty,
        Qty: 1,
        Voltage: data.Voltage,
        InternalResistance: data.InternalResistance,
        Exterior: data.Exterior,
        Smell: data.Smell,
        Img1: data.Img1,
        Img2: data.Img2,
        Img3: data.Img3,
        Img4: data.Img4,
        Img5: data.Img5,
        Remark: data.Remark,
      });
      if (this.lst.length == 1) {
        this.formd.ApplyUserId = this.$store.state.user.Id;
        this.$set(this.formd, "ApplyUserName", this.$store.state.user.UserName);
        this.reqStorages();
        this.reqItems();
      }
    },
    close() {
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.id = 0;
      this.$refs.form.resetFields();
      this.formd = { Type: 1, SubType: 1, Status: 1 };
      this.lst = [];
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // for (let a of this.lst) {
          //   if (a.Qty > a)
          // }
          this.postData();
        }
      });
    },
    del(i) {
      this.lst.splice(i, 1);
    },
    postData() {
      let param = {};
      param.Main = this.$util.copy(this.formd);
      param.Main.BtmsOrderId = this.orderId;
      if (this.formd.BillDate != null && typeof this.formd.BillDate == "object") {
        param.Main.BillDate = this.formd.BillDate.format("yyyy-MM-dd");
      }
      param.Items = this.lst;
      this.posting = true;
      this.$axios
        .post("/Api/WmsStockIn/AddEx", param)
        .then((res) => {
          if (res.result) {
            this.$Message.success("提交成功");
            this.$emit("on-add");
            this.close();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
          this.posting = false;
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择人员
    toPickUser() {
      let def = [];
      if (this.formd.ApplyUserId) {
        def = [
          {
            Id: this.formd.ApplyUserId,
            UserName: this.formd.ApplyUserName,
          },
        ];
      }
      this.$refs.PickUser.open(def);
    },
    onPickUser(lst) {
      if (lst[0]) {
        this.formd.ApplyUserId = lst[0].Id;
        this.$set(this.formd, "ApplyUserName", lst[0].UserName);
      } else {
        this.formd.ApplyUserId = "";
        this.$set(this.formd, "ApplyUserName", "");
      }
    },
    unPickUser() {
      this.formd.ApplyUserId = "";
      this.$set(this.formd, "ApplyUserName", "");
    },
    // 仓库
    reqStorages() {
      this.$axios.get("/Api/WmsStorage/QueryAll").then((res) => {
        if (res.result) {
          this.storageLst = res.data;
        }
      });
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.orderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
  },
};
</script>
<style lang="less">
.bom-stock-in-drawer {
  .ivu-drawer-mask {
    background-color: transparent;
    right: 500px;
  }
  .ivu-drawer-wrap {
    right: 500px;
  }
  .ivu-form-item {
    width: 50%;
    margin-right: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
  .stock-in-item {
    padding: 10px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;
    .ivu-form-item {
      margin: 0;
      width: auto;
    }
    .ivu-form-item + .ivu-form-item {
      margin-left: 10px;
    }
    .ivu-form-item-label {
      padding: 10px 0;
      color: #808695;
    }
    .close {
      position: absolute;
      top: 0;
      right: 2px;
    }
  }
}
</style>

