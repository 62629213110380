<template>
  <div class="bom-send-trace">
    <div class="bk-drawer-body">
      <div class="flex">
        <Button
          icon="md-refresh"
          size="small"
          @click="reqFlowTrace"
        ></Button>
        <Button
          type="primary"
          size="small"
          style="margin-left: 10px"
          @click="open()"
          ><Icon type="md-add" />发布消息</Button
        >
      </div>
      <div
        class="bk-loading-line"
        style="padding: 20px 0 0"
        v-if="traceLoading"
      >
        <Icon type="ios-loading"></Icon>
        <span>Loading</span>
      </div>
      <div
        class="text-sub"
        style="padding: 20px 0 0"
        v-if="!traceLoading && traceLst.length == 0"
      >
        暂无数据
      </div>
      <div class="trace-item" v-for="(a, i) in traceLst" :key="`trace-${i}`">
        <div class="left">
          <div class="info">
            {{ a.Content
            }}<img
              class="sign-img"
              v-if="a.SignImg"
              :src="a.SignImg"
              @click="viewImg(a.SignImg)"
            />
          </div>
          <div class="sub-info">
            <span v-if="a.Party == 1">委托方</span>
            <span v-if="a.Party == 2">倍特睿</span>
            <span> - {{ a.CreateUserName }} / {{ a.CreateTime }}</span>
          </div>
        </div>
        <div class="right" v-for="(b, j) in a.Replys" :key="`trace-${i}-${j}`">
          <div class="info">
            {{ b.Content
            }}<img
              class="sign-img"
              v-if="b.SignImg"
              :src="b.SignImg"
              @click="viewImg(b.SignImg)"
            />
          </div>
          <div class="sub-info">
            <span v-if="b.Party == 1">委托方</span>
            <span v-if="b.Party == 2">倍特睿</span>
            <span> - {{ b.CreateUserName }} / {{ b.CreateTime }}</span>
          </div>
        </div>
        <div class="right" v-if="a.Party == 1 && !a.Replys">
          <div class="to-comment" @click="open(a.Id)">回复</div>
        </div>
      </div>
    </div>

    <Modal v-model="traceFlag" title="发布消息" width="400" @on-cancel="close">
      <Form
        @submit.native.prevent
        :model="traceData"
        :rules="rule"
        ref="traceForm"
      >
        <FormItem prop="Content" style="margin: 0"
          ><Input
            v-model="traceData.Content"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
        /></FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" :loading="tracePosting" @click="ok"
          >确定</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      orderId: 0,
      // ——————————
      traceLoading: false,
      traceLst: [],
      // ——————————
      traceFlag: false,
      tracePosting: false,
      traceData: {},
      rule: { Content: [{ required: true, message: " " }] },
    };
  },
  methods: {
    init(orderId) {
      this.orderId = orderId;
      this.reqFlowTrace();
    },
    clear() {
      this.traceLst = [];
      this.traceFlag = false;
      this.traceLoading = false;
      this.close();
    },
    // 消息
    reqFlowTrace() {
      this.traceLst = [];
      this.traceLoading = true;
      this.$axios
        .get("/Api/BtmsFlowTrace/QueryPg", {
          params: {
            FlowCode: "BOM-RECEIVE",
            OrderId: this.orderId,
            PageIndex: 1,
            PageSize: 99,
          },
        })
        .then((res) => {
          this.traceLoading = false;
          if (res.result) {
            this.traceLst = res.data.DataSet;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.traceLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    open(TraceId) {
      this.traceFlag = true;
      this.traceData.TraceId = TraceId || 0;
    },
    close() {
      this.traceFlag = false;
      this.tracePosting = false;
      this.traceData = {};
    },
    ok() {
      this.$refs.traceForm.validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          formdata.append("OrderId", this.orderId);
          formdata.append("DataId", this.orderId);
          formdata.append("TraceId", this.traceData.TraceId);
          formdata.append("Content", this.traceData.Content);
          formdata.append("FlowCode", "BOM-RECEIVE");
          // for (let file of files) {
          //   formdata.append("File", file);
          // }
          let config = { headers: { "Content-Type": "multipart/form-data" } };
          this.tracePosting = true;
          this.$axios
            .post("/BtmsOrderEx/AddFlowTrace2", formdata, config)
            .then((res) => {
              this.tracePosting = false;
              if (res.result) {
                this.$Message.success("发布成功");
                this.close();
                this.reqFlowTrace();
              } else {
                this.$Message.error(res.errormsg);
              }
            })
            .catch((err) => {
              this.tracePosting = false;
              this.$Message.error(err.errormsg);
            });
        }
      });
    },
    viewImg(img) {
      this.$root.$emit("view-imgs", [img]);
    },
  },
};
</script>
<style lang="less">
.bom-send-trace {
  .trace-item {
    padding: 5px 10px;
    .info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .right .info {
      justify-content: flex-end;
    }
    .sign-img {
      width: 60px;
      height: 30px;
      cursor: pointer;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      margin: 0 5px;
      background: #fff;
    }
  }
}
</style>
