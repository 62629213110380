<template>
  <div id="BomSendDescription">
    <Modal @on-cancel="close" title="编辑描述" v-model="flag">
      <Input :rows="4" type="textarea" v-model="text" />
      <div slot="footer">
        <Button @click="close" type="text">取消</Button>
        <Button :loading="loading" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      loading: false,
      id: 0,
      text: "",
    };
  },
  methods: {
    open(id, text) {
      this.flag = true;
      this.id = id;
      this.text = text;
    },
    close() {
      this.flag = false;
      this.loading = false;
      this.id = 0;
      this.text = "";
    },
    ok() {
      this.loading = true
      this.$axios.post("/Api/BtmsBomSend/Update", {
        Id: this.id,
        Description: this.text
      }).then((res) => {
          this.loading = false;
          if (res.result) {
            this.$Message.success("编辑成功")
            this.$emit("on-upd", [this.id, this.text])
            this.close()
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>