<template>
  <div class="BomSendAdd">
    <Modal
      :styles="{top:'50px'}"
      @on-cancel="close"
      class="bom-send-add-modal"
      title="新增寄送记录"
      v-model="flag"
    >
      <Form :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem label="委托单：" prop="OrderId" required>
          <Button
            @click="fastPickOrder"
            ghost
            size="small"
            style="margin-bottom: 8px"
            type="primary"
            v-if="$store.state.order.Id"
          >快速选择：{{ $store.state.order.Code }}</Button>
          <Input :value="formd.OrderCode" @on-clear="unPickOrder" clearable readonly>
            <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
          </Input>
        </FormItem>
        <FormItem label="描述：">
          <Input :rows="4" type="textarea" v-model="formd.Description" />
        </FormItem>
        <FormItem label="图片：">
          <div class="attas">
            <input
              @change="onFiles"
              accept="image/*"
              multiple
              ref="attasInput"
              style="display: none"
              type="file"
            />
            <div
              @click="
                () => {
                  $refs.attasInput.click();
                }
              "
              class="bk-add-file"
              style="margin-right: 10px"
            >
              <Icon color="#808695" size="20" type="md-add"></Icon>
            </div>
            <div :key="`img-${i}`" class="atta" v-for="(img, i) in imgs">
              <img :src="img" @click="viewImg(i)" alt />
              <Icon
                @click="toRemoveImg(i)"
                class="icon-remove"
                color="#ed4014"
                size="20"
                type="md-close-circle"
              />
            </div>
          </div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="close" type="text">取消</Button>
        <Button :loading="loading" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
export default {
  components: { PickOrder },
  data() {
    return {
      flag: false,
      loading: false,
      formd: {},
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
      },
      imgs: [],
      files: [],
    };
  },
  methods: {
    open() {
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.loading = false;
      this.formd = {};
      this.imgs = [];
      this.files = [];
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      let formdata = new FormData();
      formdata.append("OrderId", this.formd.OrderId);
      formdata.append("Description", this.formd.Description);
      for (let a of this.files) {
        formdata.append("File", a);
      }
      this.loading = true;
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/BtmsOrderEx/BomSend", formdata, config)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.$Message.success("新增成功");
            this.$emit("on-add");
            this.close()
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error(err.errormsg);
        });
    },
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
    },
    viewImg(i) {
      this.$root.$emit("view-imgs", this.imgs, i);
    },
    onFiles(e) {
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.imgs.push(this.$util.file2url(e.target.files[i]));
          this.files.push(e.target.files[i]);
        }
      }
      e.target.value = "";
    },
    toRemoveImg(i) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除图片",
        onOk: () => {
          this.removeImg(i);
        },
      });
    },
    removeImg(i) {
      this.imageList.splice(i, 1);
      this.imageList_.splice(i, 1);
    },
  },
};
</script>
<style lang="less">
.bom-send-add-modal {
  .attas {
    display: flex;
    flex-wrap: wrap;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -2px;
      right: -2px;
      cursor: pointer;
      border-radius: 50%;
      background: #fff;
    }
  }
}
</style>