<template>
  <div id="receiveAndTrace">
    <Drawer
      class="bk-sub-drawer receive-and-trace-drawer"
      v-model="flag"
      :transfer="false"
      :mask-closable="false"
      width="500"
      @on-close="close"
    >
      <Tabs :value="tabIndex" :animated="false">
        <TabPane label="物料接收" name="0">
          <receive ref="receive" @to-open="openReceiveItem" @to-stockIn="toStockIn" />
        </TabPane>
        <TabPane label="反馈消息" name="1">
          <trace ref="trace" />
        </TabPane>
      </Tabs>
    </Drawer>
    <receive-item ref="receiveItem" @on-add="onReceiveAdd" @on-upd="onReceiveUpd" />
    <stock-in ref="stockIn" @on-add="onStockIn" />
  </div>
</template>
<script>
import receive from "./receive.vue"
import receiveItem from "./receive-item.vue"
import trace from "./trace.vue"
import stockIn from "./stockIn";
export default {
  components: { receive, receiveItem, trace, stockIn },
  data() {
    return {
      sendId: 0,
      orderId: 0,
      orderCode: "",
      flag: false,
      tabIndex: 0
    };
  },
  methods: {
    open(data) {
      this.flag = true;
      this.sendId = data.SendId;
      this.orderId = data.OrderId;
      this.orderCode = data.OrderCode;
      this.$refs.receive.init(data.SendId, data.OrderId)
      this.$refs.trace.init(data.OrderId)
    },
    close() {
      this.flag = false;
      this.tabIndex = 0;
      this.$refs.receive.clear()
      this.$refs.trace.clear()
      this.$refs.stockIn.close()
    },
    openReceiveItem(id) {
      this.$refs.receiveItem.open(id, this.sendId, this.orderId);
    },
    onReceiveAdd() {
      this.$refs.receive.reqBomSend()
      this.$refs.receive.pi = 1
      this.$refs.receive.reqLst()
    },
    onReceiveUpd() {
      this.$refs.receive.pi = 1
      this.$refs.receive.reqLst()
    },
    toStockIn(data) {
      this.$refs.stockIn.add(data, this.orderId, this.orderCode);
    },
    onStockIn() {
      this.$refs.receive.reqLst()
    }
  },
};
</script>
<style lang="less">
.receive-and-trace-drawer {
  .ivu-drawer {
    border-left: 1px solid #dcdee2;
  }
  .bk-drawer-body {
    top: 53px;
  }
  .ivu-drawer-body {
    height: 100%;
    padding: 0;
  }
  .ivu-tabs {
    height: 100%;
  }
  .ivu-tabs-nav-scroll {
    padding-top: 16px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) inset;
  }
  .ivu-tabs-bar {
    margin-bottom: 0;
  }
  .ivu-tabs-content {
    height: calc(~"100% - 53px");
  }
  .ivu-tabs-tabpane {
    height: 100%;
  }
}
</style>
