<template>
    <Modal class="bom-send-attach-modal" v-model="flag">
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>加载寄送清单...</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="reqAttach">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
    </Modal>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      flag: false,
      loading: false,
      failed: false,
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.reqAttach();
      this.flag = true;
    },
    close() {
      this.flag = false;
    },
    reqAttach() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 7,
            DataId: this.id,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            if (res.data && res.data.length > 0) {
              if (this.flag) {
                let imageList = [];
                for (let a of res.data) {
                  imageList.push(a.Url);
                }
                this.$root.$emit("view-imgs", imageList);
                this.close()
              }
            }
          } else if (res.errormsg) {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>
<style lang="less">
.bom-send-attach-modal {
  .ivu-modal {
    border-radius: 4px;
    overflow: hidden;
  }
  .ivu-modal-body {
    height: 100px;
  }
  .ivu-modal-footer {
    display: none;
  }
}
</style>
