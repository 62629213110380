<template>
  <div class="bk-page" id="BomSend">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form @submit.native.prevent class="bk-page-search" inline>
          <FormItem :label-width="60" label="委托单">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem :label-width="80" label="测试项目">
            <Select clearable v-model="searchData1.ItemId">
              <Option
                :key="`item-${item.Id}`"
                :value="item.Id"
                v-for="item in orderItems"
              >{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="btns">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="onCollapseAll" type="default">全部收起</Button>
            <Button @click="toAdd" type="primary" v-if="$able.map.BtmsBomSendAndRecAdd">新增</Button>
          </FormItem>
        </Form>
        <!-- <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table> -->
        <input
          @change="onFiles"
          accept="image/*"
          multiple
          ref="attasInput"
          style="display: none"
          type="file"
        />
        <Card dis-hover v-show="tableLoading">
          <Spin class="bk-loading" style="padding:30px">
            <Icon style="margin-bottom:10px;" type="ios-loading"></Icon>
            <div>加载中</div>
          </Spin>
        </Card>
        <div v-show="!tableLoading">
          <Card
            :class="item._collapse?'collapse':''"
            :key="i"
            dis-hover
            v-for="(item, i) in tableData"
          >
            <div slot="title">
              <div>
                <Icon
                  :type="item._collapse?'ios-arrow-forward':'ios-arrow-down'"
                  @click="onCollapse(i)"
                />
                <span>委托单号：</span>
                <span class="text-primary">{{ item.OrderCode }}</span>
                <Divider type="vertical" />
                <span v-show="item.Party==1">委托方：</span>
                <span v-show="item.Party==2">我方：</span>
                <span>{{item.CreateUserName}}</span>
                <span class="text-xs text-sub" style="margin-left:5px">{{item.CreateTime}}</span>
                <Divider type="vertical" />
                <Badge
                  :status="$designConst.bomSend.StatusBadge[item.Status]"
                  :text="$designConst.bomSend.Status[item.Status]"
                />
                <Divider type="vertical" />
                <Button
                  @click="toReceive(i)"
                  size="small"
                  type="primary"
                  v-if="$able.map.BtmsBomSendAndRecRec"
                >物料接收</Button>
              </div>
            </div>
            <div>
              <div class="attas">
                <div :key="`${i}-${j}`" class="atta" v-for="(img, j) in item.SendAttas">
                  <img :src="img.Url" @click="viewImg(i, j)" alt />
                  <Icon
                    @click="toRemoveImg(i, j)"
                    class="icon-remove"
                    color="#ed4014"
                    size="20"
                    type="md-close-circle"
                    v-if="$able.map.BtmsBomSendAndRecUpd"
                  />
                </div>
                <div :key="`up-${i}-${j}`" class="atta" v-for="(img, j) in item.SendAttas_">
                  <img :src="img" alt />
                  <div class="bk-loading">
                    <Icon size="20" type="ios-loading" />
                  </div>
                </div>
                <div
                  @click="toPickFiles(i)"
                  class="bk-add-file"
                  style="margin-right: 10px"
                  v-if="$able.map.BtmsBomSendAndRecUpd"
                >
                  <Icon color="#808695" size="20" type="md-add"></Icon>
                </div>
              </div>
              <div style="margin-top:5px">
                <span class="no-text">{{item.Description}}</span>
                <Icon
                  @click="toDesc(i)"
                  class="pointer"
                  color="#2d8cf0"
                  style="margin-left:5px"
                  type="md-create"
                  v-if="$able.map.BtmsBomSendAndRecUpd"
                />
              </div>
              <div class="flex items-center" style="margin-top:5px" v-if="item.SignUserId">
                <div>{{item.SignUserName}}</div>
                <div class="text-xs text-sub" style="margin:0 5px">{{item.SignTime}}</div>
                <img :src="item.SignImg" alt class="pointer" style="width:50px;height:25px" />
              </div>
            </div>
          </Card>
        </div>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <attach ref="attach" />
    <!-- <receive ref="receive" @on-upd="reqTable" /> -->
    <receive-and-trace ref="receiveAndTrace" />
    <add @on-add="onSearch" ref="add" />
    <description @on-upd="onDesc" ref="desc" />
  </div>
</template>
<script>
import add from "./add.vue";
import description from "./description.vue";
import PickOrder from "../../components/PickOrder.vue";
import attach from "./attach";
import receiveAndTrace from "./receive-and-trace.vue";
export default {
  components: { add, description, PickOrder, attach, receiveAndTrace },
  data() {
    return {
      orderItems: [],
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
      // ——————————
      ctrl_data_i: -1,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      // this.$refs.attach.close()
      // this.$refs.receive.close()
      if (this.$refs.receiveAndTrace.flag) {
        this.$refs.receiveAndTrace.close();
      }
      if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code,
        };
        this.reqItems();
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
    },
    // 初始化搜索
    initSearch() {
      this.reSearchData();
      this.pageIndex = 1;
      this.onSearch();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reqTable() {
      if (this.$able.map.BtmsBomSendAndRecQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = this.searchData2;
      params.PageIndex = this.pageIndex;
      params.PageSize = this.pageSize;
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsBomSend/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            let data = res.data.DataSet || []
            for (let i in data) {
              data[i]._collapse = true
            }
            this.tableData = data;
            this.recordCount = res.data.RecordCount;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
        this.reqItems();
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
      this.searchData1.ItemId = 0;
      this.orderItems = [];
    },
    // 测试项目
    reqItems() {
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", {
          params: {
            PageIndex: 1,
            PageSize: 99,
            OrderId: this.searchData1.OrderId,
          },
        })
        .then((res) => {
          if (res.result) {
            this.orderItems = res.data.DataSet;
          }
        });
    },
    toAdd() {
      this.$refs.add.open();
    },
    onCollapse(i) {
      this.tableData[i]._collapse = !this.tableData[i]._collapse;
      this.tableData = this.tableData.concat([]);
    },
    onCollapseAll() {
      for (let i in this.tableData) {
        this.tableData[i]._collapse = true;
      }
      this.tableData = this.tableData.concat([]);
    },
    toPickFiles(i) {
      this.ctrl_data_i = i;
      this.$refs.attasInput.click();
    },
    onFiles(e) {
      this.tableData[this.ctrl_data_i].SendAttas_ = [];
      let files = [];
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          // this.imageList.push(this.$util.file2url(e.target.files[i]));
          this.tableData[this.ctrl_data_i].SendAttas_.push(
            this.$util.file2url(e.target.files[i])
          );
          files.push(e.target.files[i]);
        }
        this.saveReceiveAttas(files, this.ctrl_data_i);
        this.tableData = this.tableData.concat([]);
      }
      e.target.value = "";
    },
    viewImg(i, j) {
      this.$root.$emit(
        "view-imgs",
        this.tableData[i].SendAttas.map((v) => v.Url),
        j
      );
    },
    toRemoveImg(i, j) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除图片",
        onOk: () => {
          this.removeImg(i, j);
        },
      });
    },
    removeImg(i, j) {
      let a = this.tableData[i].SendAttas.splice(j, 1);
      this.tableData = this.tableData.concat([]);
      this.$axios
        .post("/Api/DataAttachment/Delete", {
          Ids: a[0].Id,
        })
        .then((res) => {
          if (res.result) {
          } else {
            this.reqTable();
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.reqTable();
          this.$Message.error(err.errormsg);
        });
    },
    saveReceiveAttas(files, i) {
      let formdata = new FormData();
      let sendid = this.tableData[i].Id;
      formdata.append("OrderId", this.tableData[i].OrderId);
      formdata.append("DataId", this.tableData[i].Id);
      formdata.append("Type", 7);
      for (let a of files) {
        formdata.append("File", a);
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/File/PostObject", formdata, config)
        .then((res) => {
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqAttas(sendid);
          } else {
            this.reqTable();
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.reqTable();
          this.$Message.error(err.errormsg);
        });
    },
    reqAttas(id) {
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 7,
            DataId: id,
          },
        })
        .then((res) => {
          if (res.result) {
            if (res.data && res.data.length > 0) {
              for (let i in this.tableData) {
                if (this.tableData[i].Id == id) {
                  this.tableData[i].SendAttas = res.data;
                  this.tableData[i].SendAttas_ = [];
                  break;
                }
              }
              this.tableData = this.tableData.concat([]);
            }
          } else if (res.errormsg) {
            this.failed = true;
            this.reqTable();
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.reqTable();
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toReceive(i) {
      this.$refs.receiveAndTrace.open({
        SendId: this.tableData[i].Id,
        OrderId: this.tableData[i].OrderId,
        OrderCode: this.tableData[i].OrderCode,
      });
    },
    toDesc(i) {
      this.$refs.desc.open(this.tableData[i].Id, this.tableData[i].Description);
    },
    onDesc(data) {
      for (let i in this.tableData) {
        if (this.tableData[i].Id == data[0]) {
          this.tableData[i].Description = data[1];
          this.tableData = this.tableData.concat([]);
          break;
        }
      }
    },
  },
};
</script>
<style lang="less">
#BomSend {
  .ivu-card {
    margin-top: 10px;
  }
  .ivu-card-head,
  .ivu-card-body {
    padding: 10px;
  }
  .ivu-card-head {
    padding-left: 30px;
    position: relative;
    .ivu-icon-ios-arrow-down,
    .ivu-icon-ios-arrow-forward {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 5px;
      top: 12px;
      line-height: 20px;
      cursor: pointer;
    }
  }
  .ivu-card-head > div {
    font-size: 14px;
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .collapse {
    .ivu-card-head {
      border-bottom: none;
    }
    .ivu-card-body {
      display: none;
    }
  }
  .no-text:empty::after {
    content: "无描述内容";
    color: #c5c8ce;
  }

  .attas {
    display: flex;
    flex-wrap: wrap;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -2px;
      right: -2px;
      cursor: pointer;
      border-radius: 50%;
      background: #fff;
    }
    .bk-loading {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }
}
.desc-modal {
  .ivu-modal-footer {
    border-top: 0;
  }
}
</style>

