<template>
  <div class="bom-receive">
    <div class="bk-drawer-body">
      <div style="position: relative; padding-bottom: 10px; margin-bottom: 10px">
        <div class="bk-loading" style="color:#2d8cf0" v-if="attaLoading != 0">
            <Icon type="ios-loading" style="margin-right:10px;"></Icon>
            <span v-if="attaLoading == -1">删除中...</span>
            <span v-if="attaLoading == 1">加载中...</span>
            <span v-if="attaLoading == 2">提交中...</span>
        </div>
        <div class="attas">
          <input
            @change="onFiles"
            accept="image/*"
            multiple
            ref="attasInput"
            style="display: none"
            type="file"
          />
          <div
            @click="
              () => {
                $refs.attasInput.click();
              }
            "
            class="bk-add-file"
            style="margin-right: 10px"
            v-if="editable"
          >
            <Icon color="#808695" size="20" type="md-add"></Icon>
          </div>
          <div :key="`img-${i}`" class="atta" v-for="(img, i) in imageList">
            <img :src="img" @click="viewImg(i)" alt />
            <Icon
              @click="toRemoveImg(i)"
              class="icon-remove"
              color="#ed4014"
              size="20"
              type="md-close-circle"
              v-if="editable"
            />
          </div>
        </div>
        <div class="text-sub" style="margin-top:5px" v-if="editable">请上传物料接收相关信息（如物流单拍照等），最多5张</div>
      </div>
      <Form :label-width="82" :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem label="接收清单：" style="margin: 0 0 8px">
          <Button
            @click="toReceive"
            ghost
            icon="md-add"
            size="small"
            type="primary"
            v-if="editable"
          >添加</Button>
        </FormItem>
      </Form>
      <div style="position: relative">
        <Spin class="bk-loading" fix v-if="loading">
          <div>
            <Icon type="ios-loading"></Icon>
            <div>Loading</div>
          </div>
        </Spin>
        <Spin class="bk-failed" fix v-if="failed">
          <div @click="reqLst">
            <Icon type="md-refresh"></Icon>
            <p>Refresh</p>
          </div>
        </Spin>
        <!-- <Button type="dashed" long @click="toReceive" style="margin-bottom:5px;">
            <Icon type="ios-add" size="20" color="#808695" />
        </Button>-->
        <div :key="`bom-receive-${i}`" class="bom-receive-lst-item" v-for="(item, i) in lst">
          <div class="flex">
            <div @click="viewImgs(i)" class="img-box">
              <img :src="item.Img1" alt />
              <span>{{ item.imgCount }}张</span>
            </div>
            <div class="flex-sub">
              <div class="text-xs">
                名称：{{ item.Name}}{{ item.Type == 1 ? `(${item.Code})` : "" }}，
                {{item.Type==1?"样品":"其他物料"}}，
                接收数量：{{item.Qty}}，
                入库数量：{{ item.StorageQty }}
              </div>
              <div class="text-xs">
                <span>电压：{{ item.Voltage }}；</span>
                <span>内阻：{{ item.InternalResistance }}；</span>
                <span>外观：{{ item.Exterior }}；</span>
                <span>气味：{{ item.Smell }}；</span>
              </div>
              <div class="text-xs">
                其他说明：
                <span v-if="item.Remark">{{ item.Remark }}</span>
                <span class="text-off" v-else>无</span>
              </div>
              <div class="text-right">
                <span class="text-xs text-sub fl">{{ item.ReceiveDate }}</span>
                <span
                  @click="toStockIn(i)"
                  style="color: #2d8cf0; cursor: pointer; margin-right: 10px"
                  v-if="$able.map.WmsStockInAdd && item.StorageQty < item.Qty"
                >入库</span>
                <span
                  @click="editReceive(item.Id)"
                  style="color: #2d8cf0; cursor: pointer; margin-right: 10px"
                  v-if="editable"
                >编辑</span>
                <span
                  @click="toDel(item)"
                  style="color: #ed4014; cursor: pointer; margin-right: 5px"
                  v-if="editable"
                >删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bk-drawer-footer">
      <Page
        :current="pi"
        :page-size="ps"
        :total="all"
        @on-change="(index) => onChangePage(index, 0)"
        @on-page-size-change="(size) => onChangePage(1, size)"
        simple
        style="float: left; height: 32px; line-height: 32px"
      />
      <Button
        :loading="overLoading"
        @click="overReceive"
        icon="md-notifications"
        type="info"
        v-if="dtl.Status == 90 || dtl.Status == 2"
      >提醒客户签字确认</Button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      orderId: 0,
      sendId: 0,
      id: 0,
      dtl: {},
      editable: false,
      // ——————————
      imageList: [],
      imageList_: [],
      delList: [],
      attaLoading: 0,
      // ——————————
      loading: false,
      failed: false,
      pi: 1,
      ps: 5,
      all: 0,
      lst: [],
      // ——————————
      formd: {},
      rules: {},
      // ——————————
      overLoading: false,
    };
  },
  methods: {
    init(sendId, orderId) {
      this.sendId = sendId;
      this.orderId = orderId;
      this.reqBomSend();
      this.reqLst();
      this.reqAttas();
    },
    clear() {
      this.sendId = 0;
      this.orderId = 0;
      this.editable = false;
      this.attaLoading = 0;
      this.imageList = [];
      this.imageList_ = [];
      this.loading = false;
      this.failed = false;
      this.lst = [];
      this.pi = 1;
      this.all = 0;
      this.dtl = {};
    },
    reqBomSend() {
      this.$axios
        .get("/Api/BtmsBomSend/GetVo", { params: { Id: this.sendId } })
        .then((res) => {
          if (res.result) {
            this.dtl = res.data;
            this.editable =
              this.$able.map.BtmsBomSendAndRecRec &&
              (this.dtl.Status == 1 ||
                this.dtl.Status == 2 ||
                this.dtl.Status == 90);
          }
        });
    },
    reqAttas() {
      this.attaLoading = 1;
      this.$axios
        .get("/Api/DataAttachment/Query", {
          params: {
            Type: 8,
            OrderId: this.orderId,
            DataId: this.sendId,
          },
        })
        .then((res) => {
          this.attaLoading = 0;
          if (res.result) {
            this.imageList = res.data.map((v) => v.Url);
            this.imageList_ = res.data;
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attaLoading = 0;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    reqLst() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsBomReceive/QueryPg", {
          params: {
            PageIndex: this.pi,
            PageSize: this.ps,
            OrderId: this.orderId,
            SendId: this.sendId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            let lst = [];
            for (let i in res.data.DataSet) {
              let a = res.data.DataSet[i];
              a.imgCount = 0;
              if (a.Img1) {
                a.imgCount++;
              }
              if (a.Img2) {
                a.imgCount++;
              }
              if (a.Img3) {
                a.imgCount++;
              }
              if (a.Img4) {
                a.imgCount++;
              }
              if (a.Img5) {
                a.imgCount++;
              }
              lst.push(a);
            }
            this.lst = lst;
          } else if (res.errormsg) {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onFiles(e) {
      // if (e.target.files.length > 0 && this.imageList_.length < 5) {
      //   for (let i = 0; i < e.target.files.length; i++) {
      //     if (this.imageList_.length < 5) {
      //       this.imageList.push(this.$util.file2url(e.target.files[i]));
      //       this.imageList_.push(e.target.files[i]);
      //     } else {
      //       break;
      //     }
      //   }
      //   this.saveReceiveAttas();
      // }
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.imageList.push(this.$util.file2url(e.target.files[i]));
          this.imageList_.push(e.target.files[i]);
        }
        this.saveReceiveAttas();
      }
      e.target.value = "";
    },
    viewImg(i) {
      this.$root.$emit("view-imgs", this.imageList, i);
    },
    toRemoveImg(i) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除图片",
        onOk: () => {
          this.removeImg(i);
        },
      });
    },
    removeImg(i) {
      if (this.imageList_[i].Id) {
        this.attaLoading = -1;
        this.$axios
          .post("/Api/DataAttachment/Delete", { Ids: this.imageList_[i].Id })
          .then((res) => {
            if (res.result) {
              this.reqAttas();
            } else {
              this.attaLoading = 0;
              this.$Message.error(res.errormsg);
            }
          })
          .catch((err) => {
            this.attaLoading = 0;
            this.$Message.error(err.errormsg);
          });
      } else {
        this.imageList.splice(i, 1);
        this.imageList_.splice(i, 1);
      }
    },
    saveReceiveAttas() {
      let formdata = new FormData();
      formdata.append("OrderId", this.orderId);
      formdata.append("DataId", this.sendId);
      formdata.append("Type", 8);
      let hasFile = false;
      for (let a of this.imageList_) {
        if (a.Id == undefined) {
          formdata.append("File", a);
          hasFile = true;
        }
      }
      if (hasFile) {
        this.attaLoading = 2;
      } else {
        return;
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/File/PostObject", formdata, config)
        .then((res) => {
          this.attaLoading = 0;
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqAttas();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attaLoading = 0;
          this.$Message.error(err.errormsg);
        });
    },
    viewImgs(i) {
      let imgs = [];
      if (this.lst[i].Img1) imgs.push(this.lst[i].Img1);
      if (this.lst[i].Img2) imgs.push(this.lst[i].Img2);
      if (this.lst[i].Img3) imgs.push(this.lst[i].Img3);
      if (this.lst[i].Img4) imgs.push(this.lst[i].Img4);
      if (this.lst[i].Img5) imgs.push(this.lst[i].Img5);
      this.$root.$emit("view-imgs", imgs);
    },
    toReceive() {
      this.$emit("to-open", 0);
    },
    editReceive(id) {
      this.$emit("to-open", id);
    },
    onReceiveAdd() {
      this.reqBomSend();
      this.onChangePage(1);
      this.$emit("on-upd");
    },
    overReceive() {
      this.overLoading = true;
      this.$axios
        .post("/Api/Btmsbomsend/OverReceive", { Id: this.sendId })
        .then((res) => {
          this.overLoading = false;
          if (res.result) {
            this.$Message.success("操作成功");
            this.reqBomSend();
            this.$emit("on-upd");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.overLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsBomReceive/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqLst();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toStockIn(index) {
      this.$emit("to-stockIn", this.$util.copy(this.lst[index]));
    },
  },
};
</script>
<style lang="less">
.bom-receive {
  .attas {
    display: flex;
    flex-wrap: wrap;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -2px;
      right: -2px;
      cursor: pointer;
      border-radius: 50%;
      background: #fff;
    }
  }
}
.bom-receive-lst-item {
  padding: 5px;
  border: 1px solid #dcdee2;
  border-radius: 4px;
  margin-bottom: 10px;
  .img-box {
    display: block;
    width: 58px;
    height: 58px;
    border-radius: 4px;
    flex-shrink: 0;
    margin-right: 5px;
    position: relative;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    span {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0 2px;
      border-bottom-left-radius: 4px;
      color: #fff;
      background: rgba(0, 0, 0, 0.3);
      font-size: 12px;
    }
  }
}
</style>